import React from "react";

export const logisticStatus = (value) => {
    switch (value) {
        case 'waiting':
            return 'მოლოდინის რეჟიმი';
            break
        case 'tariffIssued':
            return 'ტარიფი გაცემული';
            break
        case 'confirmed':
            return 'დადასტურდა';
            break
        case 'reject':
            return 'უარი';
            break
        case 'organizeLoad':
            return 'ტვ. ორგანიზება';
            break
        case 'domesticShipping':
            return 'შიდა გადაზიდვა';
            break
        case 'stock':
            return 'საწყობშია';
            break
        case 'instruction':
            return 'ინსტრუქცია';
            break
        case 'declaration':
            return 'დეკლარაცია';
            break
        case 'courier':
            return 'საკურიერო';
            break
        case 'archive':
            return 'არქივი';
            break
        case 'reject':
            return 'უარი';
            break
        default:
            return value
    }
}
export const declarationStatus = (value) => {
    switch (value) {
        case 'TBA':
            return 'TBA';
            break
        case 'demo':
            return 'დემო';
            break
        case 'mustPayGezi':
            return 'გადასახდელი აქვს გეზი';
            break

        case 'finish':
            return 'დასრულდა';
            break

        case 'yellow':
            return 'ყვითელი';
            break

        case 'red':
            return 'წითელი';
            break

        default:
            return ''
    }
}
export const transportInvoice = (value) => {
    switch (value) {
        case 'TBA':
            return 'TBA';
            break
        case 'isPayable':
            return 'გადასახდელია';
            break

        case 'paid':
            return 'გადაიხადა';
            break

        case 'cognition':
            return 'კონიგნაცია';
            break

        default:
            return ''
    }
}
export const awbStatus = (value) => {
    switch (value) {
        case 'active':
            return 'აქტიური';
            break
        case 'came':
            return 'ჩამოვიდა';
            break

        case 'archive':
            return 'არქივი';
            break

        default:
            return value
    }
}
export const courierStatus = (value) => {
    switch (value) {
        case 'toSend':
            return 'გასაგზავნი';
            break
        case 'current':
            return 'მიმდინარე';
            break

        case 'delivered':
            return 'მიტანილია';
            break


        default:
            return ''
    }
}

export const typeOfTransport = (value) => {
    switch (value) {
        case 'aer':
            return 'საჰაერო';
            break
        case 'sea':
            return 'საზღვაო';
            break
        case 'land':
            return 'სახმელეთო';
            break
        default:
            return ''
    }
}

export const typeOfShipment = (value) => {
    switch (value) {
        case 'import':
            return 'იმპორტი';
            break
        case 'export':
            return 'ექსპორტი';
            break
        default:
            return ''
    }
}

export const receiverCondition = (value) => {
    switch (value) {
        case 'door':
            return 'კარამდე';
            break
        case 'warehouse':
            return 'საწყობამდე';
            break
        case 'airport':
            return 'აეროპორტამდე';
            break
        case 'airportPreliminary':
            return 'აეროპორტი (წინასწარი)';
            break
        default:
            return value
    }
}

export const countryToGeo = (value) => {
    switch (value) {
        case 'georgia':
            return 'საქართველო';
            break
        case 'china':
            return 'ჩინეთი';
            break
        case 'usa':
            return 'ამერიკა';
            break
        case 'korea':
            return 'კორეა';
            break
        case 'bulgaria':
            return 'ბულგარეთი';
            break
        case 'greatBritain':
            return 'ბრიტანეთი';
            break
        case 'hungary':
            return 'უნგრეთი';
            break
        case 'poland':
            return 'პოლონეთი';
            break
        case 'italy':
            return 'იტალია';
            break
        case 'france':
            return 'საფრანგეთი';
            break
        case 'taiwan':
            return 'ტაივანი';
            break
        case 'japan':
            return 'იაპონია';
            break
        case 'turkey':
            return 'თურქეთი';
            break
        default:
            return value
    }
}
