import {
    GET_COMPANIES_REQUEST_SUCCESS,
    DELETE_COMPANY_REQUEST_SUCCESS,
    GET_COMPANY_DETAIL_REQUEST_SUCCESS,
    GET_COMPANIES_REQUEST,
    SEARCH_COMPANIES_REQUEST,
    SEARCH_COMPANIES_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    isLoading: true,
    error: null,
    array: [],
    arrayCount: 0,
    detail: {
        _id : '',
        email : '',
        phone : '',
        note : '',
    },
}

const Company = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COMPANIES_REQUEST:
            return state = {
                ...state,
                array: [],
                arrayCount: 0,
                isLoading: true,
            }
        case GET_COMPANIES_REQUEST_SUCCESS:
            return state = {
                ...state,
                array: action.callBack.result,
                arrayCount: action.callBack.resultCount,
                isLoading: false,
            }
        case SEARCH_COMPANIES_REQUEST_SUCCESS:
            return state = {
                ...state,
                array: action.callBack.result,
                isLoading: false,
            }
        case DELETE_COMPANY_REQUEST_SUCCESS:
            return {
                ...state,
                array: state.array.filter(todo => todo._id !== action.payload.id)
            }
        case GET_COMPANY_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                detail: action.callBack.result,
            }
        default:
            return state
    }
}

export default Company
